import { Locale, Messages } from './base';

const FR_FR: Locale = {
  [Messages.ERROR_CADENCE_NOT_FOUND]: 'Cette cadence n’existe pas',
  [Messages.NEW_CADENCE]: 'Creer cadence',
  [Messages.NEW_CONTACT_LIST]: 'Creer liste',
  [Messages.CONFIRM_DELETE_CONTACT_LIST]: 'Confirmer supprimer la liste',
  [Messages.CONFIRM_CANNOT_UNDO]: 'Êtes vous sûr?',
  [Messages.ERROR_BAD_LOGIN]: 'Votre email et/ou mot de passe est incorrect',
  [Messages.NAV_RESET_PW]: 'Réinitialiser le mot de passe',
  [Messages.NAV_SIGNUP]: 'Enregistrer',
  [Messages.NAV_LOGIN]: 'Entrer',
  [Messages.NAV_THREADS]: 'Fils',
  [Messages.NAV_CONTACTS]: 'Contacts',
  [Messages.NAV_MGR_METRICS]: 'French',
  [Messages.NAV_THREADS_LIST]: 'Fils',
  [Messages.NAV_CONTACT_LISTS]: 'Listes de contacts',
  [Messages.NAV_UPSELL_TRIAL]: 'S’inscrire votre compte!',
  [Messages.NAV_TRIAL_EXPIRES_IN]: 'Essai gratuit expire',
  [Messages.ASK_CREATE_ACCOUNT]: 'N’avez pas déjà enregistrer?',
  [Messages.ASK_FORGOT_PW]: 'Avez oublié votre mot de passe?',
  [Messages.ERROR_INVALID_EMAIL]: 'Veuillez mettre une adresse email valide',
  [Messages.OR]: 'ou',
  [Messages.ID]: 'id',
  [Messages.TO]: 'à',
  [Messages.AT]: 'à',
  [Messages.NEXT]: 'Prochain',
  [Messages.BACK]: 'Avant',  // TODO? this can't be right
  [Messages.EMAIL]: 'Email',
  [Messages.PW]: 'Mot de passe',
  [Messages.SAVE]: 'Sauvguarder',
  [Messages.ERROR_SIGNUP_FAILED]: 'La création du compte a échoué',
  [Messages.ASK_TRY_AGAIN]: 'Veuillez réessayer',
  [Messages.BUTTON_CADENCE_EDIT]: 'éditer',
  [Messages.BUTTON_CADENCE_ARCHIVE]: 'archiver',
  [Messages.BUTTON_CADENCE_UNARCHIVE]: 'désarchiver',
  [Messages.SELECT_PLACEHOLDER]: 'Choisir...',
  [Messages.PREVIEW]: 'Preview',
  [Messages.EDIT]: 'éditer',
  [Messages.DELETE]: 'supprimer',

  [Messages.FIRST_NAME]: 'Prénom',
  [Messages.LAST_NAME]: 'Nom',
  [Messages.ERROR_FIELD_REQUIRED]: 'Cela est obligatoire',
  [Messages.ERROR_SHORT_PW]: 'Mot de pass doit être au moins 12 lettres',
  [Messages.TOS_AGREE]: 'En enregistrant, vouz etes d’accord avec le',
  [Messages.TOS]: 'Conditions d’utilisation',
  [Messages.ASK_LOGIN]: 'Déjà enregistré?',
  [Messages.NEW_CADENCE_NAME]: 'Nouveau cadence vide',
  [Messages.NEW_CADENCE_DESC]: 'Une cadence vide pour toutes vos idées creatives.',
  [Messages.EMPTY_CADENCE_TITLE]: 'Vous n’avez pas de cadences',
  [Messages.EMPTY_CADENCE_DESC]: 'Faites le travail d’un bureau entier avec une cadence. En creez à commencer reduire votre travail.',
  [Messages.EMPTY_CONTACTS_DESC]: "Vous n'avez aucune contact",
  [Messages.EMPTY_CONTACTS_TITLE]: 'Se-connecter une compte pour voir votre contacts.',
  [Messages.NO_CONTACTS_IN_LIST_DESC]: 'Cliquez au-dessus pour ajouter une contact',
  [Messages.NO_CONTACTS_IN_LIST_TITLE]: "Il n'y a aucune contact dans cette liste",
  [Messages.ADD_CONTACT_TO_LIST]: 'Ajouter',
  [Messages.ADD_CONTACT_TO_LIST_LABEL]: 'Ajouter un contact à cette liste',
  [Messages.EMPTY_CONTACT_LISTS_DESC]: "Vous n'avez aucune liste de contacts",
  [Messages.EMPTY_CONTACT_LISTS_TITLE]: 'Cliquer le bouton pour créer votre première liste.',
  [Messages.LOADING]: 'chargement',
  [Messages.REFRESHING]: 'rechargement',
  [Messages.ERROR]: 'erreur',
  [Messages.SUCCESS]: 'Succès',
  [Messages.FAILED_TO_SAVE]: 'Échec de sauvegarder',
  [Messages.CADENCE]: 'cadence',
  [Messages.CADENCES]: 'cadences',
  [Messages.NAV_SETTINGS]: 'Préferences',
  [Messages.NAV_ACCOUNTS]: 'Intégrations',
  [Messages.NAV_PROFILE]: 'Profil',
  [Messages.NAV_LOGOUT]: 'Se déconnecter',
  [Messages.NAV_INBOXES]: 'Inbox',
  [Messages.NAV_NOTIF_SETTINGS]: 'Notifications',
  [Messages.NAV_BILLING_SETTINGS]: 'Facturation',

  [Messages.NAV_CADENCE_LIST]: 'toutes cadences',
  [Messages.NAV_CADENCE]: 'cadence',
  [Messages.NAV_CADENCES]: 'Cadences',
  [Messages.NAV_HOME]: 'Acceuil',
  [Messages.NAV_CADENCE_EDIT]: 'éditer',
  [Messages.NAV_CADENCE_ENROLLMENTS]: 'inscriptions',
  [Messages.SEND_EMAIL_TO_RESUME_CADENCE]: 'Envoyer un e-mail pour résumer la cadence',
  [Messages.CONTACT]: 'contact',
  [Messages.CONTACTS]: 'contacts',
  [Messages.ACTION]: 'action',
  [Messages.ORG]: 'organization',
  [Messages.LAST_ENROLLMENT_STEP]: 'Dernier étape',

  [Messages.CREATE_CONTACT_LIST_FROM_CSV]: 'Creer une liste de contacts de cette CSV',
  [Messages.CONTACT_LIST_NAME]: 'Nom de liste',
  [Messages.CADENCE_START]: 'Entrer',
  [Messages.NODE_TYPE_BRANCH]: 'Chemin conditionnel',
  [Messages.NODE_TYPE_APPLY_LABEL]: 'Ajouter une marque',
  [Messages.NODE_TYPE_SEND_EMAIL]: 'Envoyer un e-mail',
  [Messages.NODE_TYPE_WAIT]: 'Attendre',
  [Messages.CADENCE_ADD_STEP]: 'Ajouter une étape',
  [Messages.STEPS]: 'Étapes',
  [Messages.STEP_TYPE]: 'Type d’étape',
  [Messages.EDIT_STEP]: 'Editer étape',
  [Messages.CONDITIONS]: 'Conditions',
  [Messages.CONDITIONS_SUBTITLE]: 'Si...',
  [Messages.CONDITIONS_NEEDED]: 'Conditions nécessaires',
  [Messages.ADD_CONDITION]: 'Ajouter une condition',
  [Messages.ADD_ANOTHER_CONDITION]: 'Ajouter une condition',
  [Messages.CONDITIONS_FAIL_PROMPT]: 'Ajouter une étape d’echec',
  [Messages.LABELS]: 'Marques',

  [Messages.WEEK]: 'semaine',
  [Messages.WEEKS]: 'semaines',
  [Messages.HOUR]: 'heure',
  [Messages.HOURS]: 'heures',
  [Messages.DAY]: 'jour',
  [Messages.DAYS]: 'jours',
  [Messages.MINUTE]: 'minute',
  [Messages.MINUTES]: 'minutes',
  [Messages.ANY]: 'n’importe',
  [Messages.ALL]: 'tout',
  [Messages.AND]: 'et',
  [Messages.CONFIRM]: 'Confirmer',
  [Messages.CANCEL]: 'Annuler',
  [Messages.ACTIVE]: 'actif',
  [Messages.ARCHIVED]: 'archivé',
  [Messages.ARCHIVE]: 'archiver',
  [Messages.UNARCHIVE]: 'unarchiver',
  [Messages.NAME]: 'Nom',
  [Messages.DESCRIPTION]: 'Description',
  [Messages.INSTALL]: 'Installer',
  [Messages.CREATED_AT]: 'Crée',
  [Messages.ENROLL]: 'Inscrire',

  [Messages.EMAIL_SUBJECT]: 'Soujet',
  [Messages.EMAIL_WARN_EMPTY_LINK]: 'Attention! Un ou plus de vos links sont invalids',
  [Messages.EMAIL_BODY]: 'Contenu',
  [Messages.EMAIL_REPLY_STAYS_SAME]: 'Le sujet ne change pas pour une réponse',
  [Messages.EMAIL_SEND_AS_REPLY]: 'Envoyer comme réponse',
  [Messages.EMAIL_PAUSE_BEFORE_SENDING]: 'Permetter moi de vérification avant d’envoyer',
  [Messages.EMAIL_SEND_ON_BIZ_DAYS]: 'Ne l’envoyer pas sur les weekends ou les jours fériés',

  [Messages.HOME_HEADER]: 'Bonjour',
  [Messages.HOME_COMPARISON_NO_CHANGE]: 'Pas de change',
  [Messages.HOME_COMPARISON_UP]: 'Plus',
  [Messages.HOME_COMPARISON_DOWN]: 'Moins',
  [Messages.HOME_COMPARISON_PREV_WEEK]: 'de la semaine précédente',
  [Messages.HOME_TOPLINE_METRIC_HELP_1]: 'Emails envoyé de TinyCadence',
  [Messages.HOME_TOPLINE_METRIC_HELP_2]: 'Contacts et activités crée dans HubSpot',
  [Messages.HOME_ACTIVE_CADENCES_START]: 'Vous avez',
  [Messages.HOME_ACTIVE_CADENCES_END]: 'actives',
  [Messages.HOME_VIEW_CADENCES]: 'Voyez vos cadences',
  [Messages.HOME_CHART_HEADER]: 'TinyCadence résumé de l’activité',
  [Messages.HOME_CONNECT_GOOGLE_ACCOUNT]: 'Se connecter votre compte de Google pour commencer automatiser vos emails!',

  [Messages.UNENROLL]: 'Désincrire',
  [Messages.SIGNIN_REQUIRED]: 'Vous devez d’abord vous connecter',
  [Messages.GENERIC_CONNECTION_FAILED]: 'La connection du compte n’a pas reussi',
  [Messages.CONN_FAILED_GMAIL_SCOPES]: 'Il faut donner accés de la modification d’email à TinyCadence',
  [Messages.CONN_FAILED_GDRIVE_SCOPES]: 'French',
  [Messages.CONN_FAILED_GCAL_SCOPES]: 'Il faut donner accés de lire votre calendrier à TinyCadence',
  [Messages.CONN_FAILED_NO_GOOGLE_SCOPES]: 'Aucune permissions étaient donner à TinyCadence',
  [Messages.CONN_FAILED_PARTIAL_GOOGLE_SCOPES]: 'Il faut donner toutes les permissions à TinyCadence',
  [Messages.CONN_FAILED_PROVIDER]: 'Votre compte n’a pas bien répondé. Veuillez reconnecter.',
  [Messages.CONN_FAILED_500]: 'TinyCadence n’a pas reussi de se connecter. Notre équipe a été notifié.',
  [Messages.CONN_AUTH_TITLE]: 'Connection en cours',
  [Messages.CONN_AUTH_DESC]: 'Dès que votre compte s’est connecté, vous pouvez utiliser avec TinyCadence.',
  [Messages.CONN_AUTH_LOADING]: 'Verification du compte en cours',
  [Messages.CONN_AUTH_FAILED]: 'Votre compte n’a pas été connecté. Veuillez reconnecter.',
  [Messages.CONN_AUTH_TRY_AGAIN]: 'Veuillez reconnecter',
  [Messages.CONN_AUTH_SUCCESS]: 'Hourra! Votre compte s’est bien connecté.',
  [Messages.GENERIC_CONNECTION_SUCCESS]: 'Votre compte s’est bien connecté à TinyCadence 🎉`',

  [Messages.EDIT_BIZ_HOURS_TITLE]: 'Modifier les heures d’ouverture pour cette cadence',
  [Messages.MANUALLY_ENROLL_TITLE]: 'Faire une inscription',
  [Messages.CHOOSE_CADENCE_TEMPLATE_TITLE]: 'Choisir une cadence',
  [Messages.NEW_CADENCE_NAME_FOLLOW_UP]: 'Suivre une introduction par e-mail',
  [Messages.NEW_CADENCE_DESC_FOLLOW_UP]: 'Répondre à une introduction et ressayer après trois jours sans une réponse',

  [Messages.REMOVE_OTHER_LABELS]: 'Supprimer les autres marques',
  [Messages.ENROLL_SUCCESS]: 'Succès',
  [Messages.SCHEDULE_ENROLL_LABEL]: 'Programmer une inscription',
  [Messages.SCHEDULE_ENROLL_HELP]: 'laissez vide pour une inscription immédiate',
  [Messages.CONNECT_HUBSPOT]: 'Se connecter HubSpot',
  [Messages.CONNECT_ACCOUNT]: 'Se connecter une compte',
  [Messages.YOUR_ACCOUNTS]: 'Vos comptes',

  [Messages.CREATE_HS_CONTACTS_LABEL]: 'Créer des nouveaux contacts dans HubSpot',
  [Messages.CREATE_HS_CONTACTS_DESC]: 'Quand TinyCadence a crée un nouveau contact, chequer s’il n’exister pas dans HuSpot. Si non, TinyCadence va créer un nouveau contact avec l’addresse e-mail.',
  [Messages.LOG_HS_EMAIL_SENDS_LABEL]: 'Enregistrer des envois d’e-mails comme activité dans HubSpoot',
  [Messages.LOG_HS_EMAIL_SENDS_DESC]: 'Quand TinyCadence envoi un e-mail au contact, trouver le contact par addresse e-mail dans HubSpot. S’il existe, noter l’e-mail comme une activité dans HubSpot. (Noter: si vous avez installé l’extension Gmail de HubSpot, désactivez cette fonctionnalité pour prevenir l’activité dupliquée)',
  [Messages.PULL_HS_CTC_PROPS_LABEL]: 'Synchroniser des propriétés de contact de HubSpot à TinyCadence',
  [Messages.PULL_HS_CTC_PROPS_DESC]: 'En créant un contact dans TinyCadence, cette fonctionnalité permet TinyCadence à créer les propriétés de contact avec l’information de HubSpot.',

  [Messages.INSERT_TEMPLATE_VAR_TITLE]: 'Inserer une variable de modèle',
  [Messages.INSERT_TEMPLATE_VAR_LABEL]: 'Propriété du contact',
  [Messages.INSERT_TEMPLATE_VAR_FALLBACK_LABEL]: 'Solutions de repli',
  [Messages.INSERT_TEMPLATE_VAR_FALLBACK_HELP]: 'Que insérer si le contact n’a aucune propriété',

  [Messages.CSV_UPLOAD_HEADER]: 'Télécharger un CSV des contacts',
  [Messages.CSV_UPLOAD_DESCRIPTION]: 'Télécharger un CSV de moins de 10MB avec une ligne d’en-tête nommé "email". D’autres lignes en-tête seraient utiliser comme propriétés des contacts.',
  [Messages.CSV_UPLOAD_INVALID]: 'CSV invalid',
  [Messages.CSV_UPLOAD_INVALID_NO_CONTENT]: 'Le CSV doit avoir au moins une ligne d’en-tête et une ligne de contenu',
  [Messages.CSV_UPLOAD_INVALID_NO_EMAIL_COL]: 'Le CSV doit avoir une colonne nommée "email"',
  [Messages.CSV_UPLOAD_INVALID_BAD_ROWS]: 'Les lignes suivantes contiennent des adresses e-mail invalides: ',
  [Messages.CSV_UPLOAD_ERRORS]: 'Veuillez ouvrir le CSV pour voir les erreurs avec le téléchargement.',

  [Messages.CONTACT_DRAWER_TITLE]: 'Détails du contact',
  [Messages.CONTACT_DRAWER_HANDLES_TITLE]: 'Contact',
  [Messages.CONTACT_DRAWER_PROPERTIES_TITLE]: 'Plus de détails',

  [Messages.ONBOARDING_SKIP_TEXT]: 'French',
  [Messages.ONBOARDING_DONE_TEXT]: 'French',
  [Messages.ONBOARDING_WELCOME_HEADER]: 'French',
  [Messages.ONBOARDING_WELCOME_DESCRIPTION]: 'French',
  [Messages.ONBOARDING_CONNECT_GOOGLE_HEADER]: 'French',
  [Messages.ONBOARDING_CONNECT_GOOGLE_DESCRIPTION]: 'French',
  [Messages.ONBOARDING_INSTALL_ADDON_HEADER]: 'French',
  [Messages.ONBOARDING_INSTALL_ADDON_DESC]: 'French',
  [Messages.ONBOARDING_CONNECT_CRM_HEADER]: 'French',
  [Messages.ONBOARDING_CONNECT_CRM_DESCRIPTION]: 'French',

  [Messages.CADENCE_SETTINGS_TITLE]: 'Préferences du cadence',
  [Messages.CADENCE_EMAIL_SEND_ON_BIZ_DAYS]: 'Envoyer des emails seulement sur les jours du travail',
  [Messages.CADENCE_EMAIL_SEND_DURING_BIZ_HOURS]: 'Customizer les jours et heures pour envoyer des emails',
  [Messages.CADENCE_EMAIL_SEND_AS_REPLIES]: 'Envoyer tous les email comme une réponse',
  [Messages.CADENCE_EMAIL_PAUSE_BEFORE_SEND]: 'Permetter moi de vérification avant d’envoyer des emails',
  [Messages.CADENCE_SETTINGS_CREATED_AT]: 'Cré',
  [Messages.CADENCE_SETTINGS_UPDATED_AT]: 'Modifié',
  [Messages.CADENCE_EMAIL_UNENROLL_ON_REPLY]: 'Désincrire des contacts qui répond à un email de cette cadence',
  [Messages.CADENCE_EMAIL_UNENROLL_ON_MTG]: 'Désincrire des contacts qui fait un rendezvous avec moi',
  [Messages.CADENCE_ENROLLMENT_OPTIONS_TITLE]: 'Préferences d’enrollments du cadence',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_TITLE]: 'Enregistement automatique du HubSpot lisst',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_DESC]: 'Quand un contact est ajouté à un list de HubSpot, l’enregiste dans cette cadence.',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_SYNC_NOW]: 'Synchroniser immédiatement',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_SYNC_SUCCESS]: 'Succès, des nouveaux contacts seront enregistrer dans 1 minute',
  [Messages.CADENCE_ENROLLMENT_HS_LIST_SYNC_TOO_FAST]: 'Veuillez attendre 5 minutes avant reessayer',

  [Messages.HOME_MERGE_CONTACTS_PROMPT]: 'French',
  [Messages.MERGE_CONTACTS_DIALOG_TITLE]: 'French',
  [Messages.MERGE_CONTACTS_DIALOG_DESC]: 'French',
  [Messages.MERGE_CONTACTS_DIALOG_WARNING]: 'French',
  [Messages.MERGE_CONTACTS_DIALOG_PRIMARY_LABEL]: 'French',
  [Messages.MERGE_CONTACTS_DIALOG_SECONDARY_LABEL]: 'French',

  [Messages.FREE_TRIAL_EXPIRED_TITLE]: 'French',
  [Messages.FREE_TRIAL_EXPIRED_DESC]: 'French',
  [Messages.FREE_TRIAL_EXPIRED_BTN]: 'French',

  [Messages.CADENCE_EVENT_ENROLLED_TITLE]: 'Contact enregistré',
  [Messages.CADENCE_EVENT_ENROLLED_DESC]: 'enrigestrement commencé à',
  [Messages.CADENCE_EVENT_ENROLLED_FROM_GMAIL]: 'Gmail Add-On',
  [Messages.CADENCE_EVENT_ENROLLED_FROM_SITE]: 'TinyCadence site',
  [Messages.CADENCE_EVENT_ENROLLED_FROM_HS_LIST]: 'HubSpot list',

  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_TITLE]: 'Contact crée dans HubSpot',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_DESC]: 'Resultat:',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_SUCCESS]: 'succès',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_ALREADY_EXISTS]: 'contact déjà existé',
  [Messages.CADENCE_EVENT_HS_CONTACT_CREATE_FAIL]: 'échec de creer le contact dans HubSpot',

  [Messages.CADENCE_EVENT_PAUSED_TITLE]: 'Franch',
  [Messages.CADENCE_EVENT_PAUSED_DESC]: 'French',
  [Messages.CADENCE_EVENT_PAUSED_DRAFT]: 'French',

  [Messages.CADENCE_EVENT_HS_PROP_PULL_TITLE]: 'French',
  [Messages.CADENCE_EVENT_HS_PROP_PULL_DESC]: 'French',

  [Messages.CADENCE_EVENT_EMAIL_OPEN_TITLE]: 'French',
  [Messages.CADENCE_EVENT_EMAIL_OPEN_DESC]: 'French',

  [Messages.CADENCE_EVENT_EMAIL_SEND_TITLE]: 'French',
  [Messages.CADENCE_EVENT_EMAIL_SEND_DESC]: 'French',
  [Messages.CADENCE_EVENT_EMAIL_SEND_CONTENT]: 'French',

  [Messages.CADENCE_EVENT_SCHEDULED_TITLE]: 'French',

  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_TITLE]: 'French',
  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_DESC]: 'French',
  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_SUCCESS]: 'French',
  [Messages.CADENCE_EVENT_HS_ACTIVITY_CREATE_FAILED]: 'French',
  [Messages.CADENCE_PERFORMANCE_TITLE]: 'French',

  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_TITLE]: 'French',
  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_DESC]: 'French',
  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_BC_EMAIL]: 'French',
  [Messages.CADENCE_EVENT_AUTOMATED_UNENROLL_BC_MTG]: 'French',

  [Messages.VIEW_ENROLLMENT_TITLE]: 'French',
  [Messages.VIEW_ENROLLMENT_EVENTS_TIMELINE]: 'French',

  [Messages.CADENCE_EVENT_FINISHED_TITLE]: 'French',
  [Messages.CADENCE_EVENT_FINISHED_DESC]: 'French',

  [Messages.CADENCE_EVENT_LABELLED_TITLE]: 'French',
  [Messages.CADENCE_EVENT_LABELLED_DESC]: 'French',

  [Messages.CADENCE_EVENT_CANCELLED_TITLE]: 'French',
  [Messages.CADENCE_EVENT_CANCELLED_DESC]: 'French',

  [Messages.CONTACT_SUBNAV_SUMMARY]: 'French',
  [Messages.CONTACT_SUBNAV_EDIT]: 'French',
  [Messages.CONTACT_SUBNAV_METRICS]: 'French',
  [Messages.CONTACT_SUBNAV_CADENCE_EVENTS]: 'French',

  [Messages.CONTACT_EVENTS_NO_EVENTS]: 'French',
  [Messages.CADENCE_EVENT_EMAIL_SCHEDULED_TITLE]: 'French',

  [Messages.SEND_TEST_EMAIL_BTN]: 'Envoyer un email de test',
  [Messages.SEND_TEST_EMAIL_SUCCESS]: 'Un email a été envoyé à ',
  [Messages.SEND_TEST_EMAIL_FAIL]: 'Eched d’envoyer. Veuillez confirmer votre connection de Google.',
  [Messages.SEND_EMAIL]: 'Envoyer',
  [Messages.SAVING_DRAFT]: 'Sauveguarder en cours',
  [Messages.SAVE_DRAFT_SUCCESS]: 'Changements sauveguardé',
  [Messages.SAVE_DRAFT_FAILED]: 'Échec de sauveguarder',

  [Messages.HUBSPOT_SETTINGS_TITLE]: "Changer vos préférences de l'integration de HubSpot",
  [Messages.CADENCES_TITLE_LEARN_MORE]: 'Quelle est une cadence?',
  [Messages.MORE_INFO]: 'Plus d’infos',
  [Messages.ENROLL_DIALOG_HELP_LINK]: 'Apprendre plus',
}

export default FR_FR;