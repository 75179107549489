import { createRoutesFromElements, Route } from 'react-router-dom';

import Login from 'components/auth/login';
import Signup from 'components/auth/signup';
import CadencesList from 'components/cadence/CadencesList';
import EditCadence from 'components/cadence/EditCadence';
import LoginRequiredRoute from 'components/LoginRequiredRoute';
import AuthOutlet from 'outlets/Auth';
import CadencesOutlet from 'outlets/Cadences';
import _, { M } from 'constants/i18n';
import HomeView from 'components/HomeView';
import ViewCadence from 'components/cadence/ViewCadence';
import ConnectSuccessView from 'components/settings/ConnectSuccessView';
import SettingsOutlet from 'outlets/Settings';
import AccountsSettings from 'components/settings/Accounts';
import CadenceEnrollmentsOutlet from 'outlets/CadenceEnrollments';
import ViewCadenceEnrollment from 'components/cadence/ViewCadenceEnrollment';
import EnrollmentsList from 'components/cadence/EnrollmentsList';
import EditEnrollment from 'components/cadence/EditEnrollment';
import NotificationsSettings from 'components/settings/Notifications';
import GeneralSettings from 'components/settings/General';
import ThreadsOutlet from 'outlets/Threads';
import AdminRequiredRoute from 'components/AdminRequiredRoute';
import AdminOutlet from 'outlets/Admin';
import AdminHomePage from 'components/admin/Home';
import AdminUsersPage from 'components/admin/UsersPage';
import AdminUserPage from 'components/admin/UserPage';
import AdminTasksPage from 'components/admin/TasksPage';
import AdminTaskPage from 'components/admin/TaskPage';
import AdminCadencesPage from 'components/admin/CadencesPage';
import AdminUserCadencesPage from 'components/admin/UserPage/cadences';
import AdminUserOutlet from 'outlets/Admin/user';
import AdminCadencePage from 'components/admin/CadencePage';
import AdminUserEnrollmentsPage from 'components/admin/UserPage/enrollments';
import AdminCadenceOutlet from 'outlets/Admin/cadence';
import AdminCadenceEnrollmentsPage from 'components/admin/CadencePage/enrollments';
import AdminEnrollmentOutlet from 'outlets/Admin/enrollment';
import AdminEnrollmentPage from 'components/admin/EnrollmentPage';
import AdminEnrollmentCadencePage from 'components/admin/EnrollmentPage/cadence';
import AdminEnrollmentContactPage from 'components/admin/EnrollmentPage/contact';
import AdminEnrollmentEventsPage from 'components/admin/EnrollmentPage/events';
import AdminWorkerJobsPage from 'components/admin/WorkerJobsPage';
import AdminWorkerJobPage from 'components/admin/WorkerJobPage';
import ContactsOutlet from 'outlets/Contacts';
import ContactsList from 'components/crm/ContactsList';
import AdminUserConnectorsPage from 'components/admin/UserPage/connectors';
import AdminConnectorPage from 'components/admin/ConnectorPage';
import AdminConnectorOutlet from 'outlets/Admin/connector';
import AdminConnectorsPage from 'components/admin/ConnectorsPage';
import MgrOutlet from 'outlets/Mgr';
import MgrHomeView from 'components/MgrHomeView';
import ContactOutlet from 'outlets/Contact';
import ContactSummary from 'components/crm/ContactSummary';
import ContactEdit from 'components/crm/ContactEdit';
import ContactMetrics from 'components/crm/ContactMetrics';
import ContactEvents from 'components/crm/ContactEvents';
import OauthReturn from 'components/auth/oauth_return';
import InboxesOutlet from 'outlets/Inboxes';
import SelectAnInbox from 'components/inbox/SelectAnInbox';
import SelectAConversation from 'components/inbox/SelectAConversation';
import ViewConversation from 'components/inbox/ViewConversation';
import CadencePerformance from 'components/cadence/CadencePerformance';
import ContactListsOutlet from 'outlets/ContactLists';
import ContactLists from 'components/crm/ContactLists';
import ContactList from 'components/crm/List';
import AdminUserContactListsPage from 'components/admin/UserPage/contact_lists';
import AdminContactListPage from 'components/admin/ContactListPage';
import AdminContactListOutlet from 'outlets/Admin/contactList';
import AdminContactListContactsPage from 'components/admin/ContactListPage/contacts';
import CadenceEnroll from 'components/cadence/CadenceEnroll';
import CadenceEnrollOutlet from 'outlets/CadenceEnroll';
import AdminUserInboxesPage from 'components/admin/UserPage/inboxes';
import AdminInboxPage from 'components/admin/InboxPage';



const routes = createRoutesFromElements(
  <>
    <Route element={<LoginRequiredRoute><HomeView /></LoginRequiredRoute>} index/>
    <Route path="/oauth-login" element={<AuthOutlet />}>
      <Route index element={<OauthReturn />} />
    </Route>

    <Route path="/auth" element={<AuthOutlet />}>
      <Route path="login" element={<Login />}/>
      <Route path="signup" element={<Signup />}/>
    </Route>

    <Route path="/inboxes" element={<LoginRequiredRoute><InboxesOutlet /></LoginRequiredRoute>}>
      <Route element={<SelectAnInbox />} index/>
      <Route path=":inboxId">
        <Route element={<SelectAConversation />} index/>
        <Route path="conversations">
          <Route path=":conversationId" element={<ViewConversation />} />
        </Route>
      </Route>
    </Route>

    <Route path="/cadences" element={<LoginRequiredRoute><CadencesOutlet /></LoginRequiredRoute>} handle={{ name: _(M.NAV_CADENCE_LIST) }}>
      <Route index element={<CadencesList />}/>
      <Route path=":cadenceId" handle={{ name: _(M.NAV_CADENCE), cadenceData: ['name'] }}>
        <Route element={<ViewCadence />} index/>
        <Route path="enroll" element={<CadenceEnrollOutlet />}>
          <Route index element={<CadenceEnroll />} />
        </Route>
        <Route path="edit" element={<EditCadence />} handle={{ name: _(M.NAV_CADENCE_EDIT) }}/>
        <Route path="performance" element={<CadencePerformance />}/>
        <Route path="enrollments" element={<CadenceEnrollmentsOutlet />} handle={{ name: _(M.NAV_CADENCE_ENROLLMENTS) }}>
          <Route index element={<EnrollmentsList />}/>
          <Route path=":enrollmentId">
            <Route index element={<ViewCadenceEnrollment />} />
            <Route path="edit" element={<EditEnrollment />} />
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="/threads" element={<LoginRequiredRoute><ThreadsOutlet /></LoginRequiredRoute>} handle={{ name: _(M.NAV_CADENCE_LIST) }}>
      <Route index element={<EnrollmentsList />}/>
    </Route>
    <Route path="/settings" element={<LoginRequiredRoute><SettingsOutlet /></LoginRequiredRoute>}>
      <Route index element={<GeneralSettings />} />
      <Route path="accounts" element={<AccountsSettings />} />
      <Route path="notifications" element={<NotificationsSettings />} />
      <Route path="billing" lazy={() => import('components/settings/Billing')} />
    </Route>
    <Route path="/connect-success" element={<LoginRequiredRoute><ConnectSuccessView /></LoginRequiredRoute>}/>
    <Route path="/contact-lists" element={<LoginRequiredRoute><ContactListsOutlet /></LoginRequiredRoute>}>
      <Route index element={<ContactLists />}/>
      <Route path=":listId">
        <Route index element={<ContactList />} />
      </Route>
    </Route>
    <Route path="/contacts" element={<LoginRequiredRoute><ContactsOutlet /></LoginRequiredRoute>}>
      <Route index element={<ContactsList />}/>
      <Route path=":contactId" element={<ContactOutlet />}>
        <Route index element={<ContactSummary />} />
        <Route path="metrics" element={<ContactMetrics />} />
        <Route path="edit" element={<ContactEdit />} />
        <Route path="events" element={<ContactEvents />} />
      </Route>
    </Route>
    <Route path="/mgr-metrics" element={<LoginRequiredRoute><MgrOutlet /></LoginRequiredRoute>}>
      <Route index element={<MgrHomeView />} />
    </Route>

    <Route path="/admin" element={<AdminRequiredRoute><AdminOutlet /></AdminRequiredRoute>}>
      <Route index element={<AdminHomePage />} />
      <Route path="users">
        <Route index element={<AdminUsersPage />}/>
        <Route path=":userId" element={<AdminUserOutlet />}>
          <Route element={<AdminUserPage />} index/>
          <Route element={<AdminUserCadencesPage />} path="cadences" />
          <Route element={<AdminUserEnrollmentsPage />} path="enrollments" />
          <Route element={<AdminUserConnectorsPage />} path="connectors" />
          <Route element={<AdminUserContactListsPage />} path="contact-lists" />
          <Route element={<AdminUserInboxesPage />} path="inboxes" />
        </Route>
      </Route>
      <Route path="tasks">
        <Route index element={<AdminTasksPage />}/>
        <Route path=":taskId">
          <Route element={<AdminTaskPage />} index/>
        </Route>
      </Route>
      <Route path="jobs">
        <Route index element={<AdminWorkerJobsPage />}/>
        <Route path=":jobId">
          <Route element={<AdminWorkerJobPage />} index/>
        </Route>
      </Route>
      <Route path="contact-lists">
        <Route path=":contactListId" element={<AdminContactListOutlet />}>
          <Route element={<AdminContactListPage />} index/>
          <Route element={<AdminContactListContactsPage />} path="contacts"/>
        </Route>
      </Route>
      <Route path="enrollments">
        <Route path=":enrollmentId" element={<AdminEnrollmentOutlet />}>
          <Route element={<AdminEnrollmentPage />} index/>
          <Route element={<AdminEnrollmentCadencePage />} path="cadence"/>
          <Route element={<AdminEnrollmentContactPage />} path="contact"/>
          <Route element={<AdminEnrollmentEventsPage />} path="events"/>
        </Route>
      </Route>
      <Route path="cadences">
        <Route index element={<AdminCadencesPage />} />
        <Route path=":cadenceId" element={<AdminCadenceOutlet />}>
          <Route element={<AdminCadencePage />} index/>
          <Route element={<AdminCadenceEnrollmentsPage />} path="enrollments" />
        </Route>
      </Route>
      <Route path="connectors">
        <Route index element={<AdminConnectorsPage />} />
        <Route path=":connectorId" element={<AdminConnectorOutlet />}>
          <Route element={<AdminConnectorPage />} index/>
        </Route>
      </Route>
      <Route path="inboxes">
        <Route path=":inboxId">
          <Route element={<AdminInboxPage />} index/>
        </Route>
      </Route>
    </Route>
  </>
);

export default routes;