import { NavLink, Outlet, useParams } from 'react-router-dom';

import { useFetchUserQuery } from 'api/admin/users';
import './style.css';

const AdminUserOutlet = () => {
  const {userId = ''} = useParams<{userId: string}>()

  const {
    data,
    isLoading,
    isFetching,
    isError,
  } = useFetchUserQuery(userId);

  if (!data || isLoading || isFetching) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  const user = data.user;

  return <div className="admin-page-content--container">
    <h2>{user.full_name}</h2>
    <div className="admin-page-content-sub-nav--container">
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/users/${userId}`} end>Summary</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/users/${userId}/cadences`}>Cadences</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/users/${userId}/enrollments`}>Enrollments</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/users/${userId}/connectors`}>Connectors</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/users/${userId}/contact-lists`}>Contact Lists</NavLink>
      <NavLink className="admin-page-content-sub-nav--link" to={`/admin/users/${userId}/inboxes`}>Inboxes</NavLink>
    </div>
    <Outlet />
  </div>
};

export default AdminUserOutlet;