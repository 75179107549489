import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { bootToLogin } from 'api';

import { API_URL } from 'constants/resources';
import { getToken } from 'utils/auth';

const tagTypes = ['Cadence', 'Connector', 'Enrollment', 'User', 'CadenceEvent', 'Contact', 'ScheduledTask', 'WorkerJob', 'Analytics', 'Inbox', 'Conversation']


const responseHandler = (response: Response) => {
  if (response.status === 401) {
    bootToLogin();
    return Promise.reject(response);
  } else {
    return response.json();
  }
};

const prepareHeaders = (headers: Headers) => {
  const token = getToken();
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  headers.set('X-App-Version', process.env.REACT_APP_VERSION || 'no-version');
  headers.set('Accept-Encoding', 'gzip');
  return headers;
}


export const adminQueries = createApi({
  reducerPath: 'adminQueries',

  tagTypes,

  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/api/admin',
    prepareHeaders,
    responseHandler,
  }),

  endpoints: () => ({}),
});

export const queries = createApi({
  reducerPath: 'apiQueries',

  tagTypes,

  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/api/v1',
    prepareHeaders,
    responseHandler,
  }),

  endpoints: () => ({}),
});

export const v2queries = createApi({
  reducerPath: 'apiV2Queries',

  tagTypes,

  baseQuery: fetchBaseQuery({
    baseUrl: API_URL + '/api/v2',
    prepareHeaders,
    responseHandler,
  }),

  endpoints: () => ({}),
});