import { Button, Checkbox, Dialog, EmptyState, Label, Spinner, TextInputField, WarningSignIcon } from 'evergreen-ui';
import { ChangeEvent, useEffect, useState } from 'react';
import { groupBy, isEmpty, prop, uniq } from 'ramda';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import Select from 'react-select';

import _, { M } from 'constants/i18n';
import { useAppSelector } from 'hooks';
import { getCurrentUser, getHasNoGitHubConnectors } from 'store/user/selector';
import { IHomeAnalyticsResponse, useHomeAnalyticsQuery } from 'api/v2/analytics';
import { getContactName, stringToColor } from 'utils/strings';
import { useManuallyConnectGitHubMutation } from 'api/connectors';

import MergeContactsDialog from './MergeContactsDialog';
import './style.css';
import { listen } from 'utils/socket';
import ProgressBar from 'components/shared/ProgressBar';


const makeChartData = (data: IHomeAnalyticsResponse, selectedOrg: string, selectedContact: number | null, selectedEventType: string | null) => {
  // create the data
  const contactResults = data.results
    .filter(res => res.org_name === selectedOrg)
    .filter(res => selectedContact === null ? true : res.actor === `ctc:${selectedContact}`)
    .filter(res => selectedEventType === null ? true : res.event_type === selectedEventType)
    .map(({actor, ...rest}) => ({
      ...rest,
      contact: data.contacts.find(ctc => actor === `ctc:${ctc.id}`),
    }));

  const chartData = Object.entries(groupBy(prop('day'), contactResults))
    .map(([dayStr, eventAndContactCountList]) => {
      const dayData: Record<string, string | number> = {
        day: dayStr,
      };

      eventAndContactCountList.forEach(({count, event_type, contact}) => {
        if (contact) {
          const name = getContactName(contact);
          if (!!selectedContact) {
            dayData[event_type] = count;
          } else {
            dayData[name] = count + ((dayData[name] as number) || 0);
          }
        }
      });

      return dayData;
    }).sort(({day: day1}, {day: day2}) => Date.parse(day1 as string) - Date.parse(day2 as string));

  // make sure to fill gaps
  const chartDataWithoutGaps: typeof chartData = [];
  const oneDay = 86400000;
  let lastDay: null | Date = null;
  const allLabels: string[] = [];
  chartData.forEach(datum => {
    const thisDay = new Date(datum.day as string);
    if (lastDay != null) {
      while (thisDay.valueOf() - lastDay.valueOf() > oneDay) {
        lastDay.setDate(lastDay.getDate() + 1);
        chartDataWithoutGaps.push({
          day: lastDay.toString(),
        });
      }
    }
    Object.keys(datum).forEach(label => {
      if (label !== 'day' && !allLabels.includes(label)) {
        allLabels.push(label);
      }
    });
    chartDataWithoutGaps.push(datum);
    lastDay = thisDay;
  });

  return chartDataWithoutGaps.map(datum => {
    allLabels.forEach(label => {
      if (datum[label] === undefined) {
        datum[label] = 0;
      }
    })
    return datum;
  });
}

const GitHubConnectDialog = ({
  isShown,
  onClose,
}: {isShown: boolean, onClose?: () => void}) => {
  const [apiKeyInput, setApiKeyInput] = useState<string>('');
  const [connect, result] = useManuallyConnectGitHubMutation();

  const submit = () => {
    if (!apiKeyInput) return;

    connect({api_key: apiKeyInput})
      .then(onClose)
  };

  return <Dialog title="Connect GitHub account" isConfirmDisabled={!apiKeyInput} isShown={isShown} isConfirmLoading={result.isLoading} onConfirm={submit} onCloseComplete={onClose}>
    <div>
      <p>In order to connect your GitHub account, you will need to create an API key (Oauth integration coming soon).</p>
      <p>To correctly create an API key, follow these steps:</p>
      <ul>
        <li>Navigate to your GitHub account access tokens (<a href="https://github.com/settings/tokens" target="_blank" rel="noreferrer">https://github.com/settings/tokens</a>)</li>
        <li>Click on the "Generate new token" dropdown and click "Generate new token (classic) <small>for general use</small>"</li>
        <li>Give the token a descriptive name</li>
        <li>Enter an expiration you are comfortable with (we recommend at least 30 days)</li>
        <li>
          <div>Allow the following permissions</div>
          <div>
            <Checkbox label="repo" checked disabled/>
            <Checkbox label="read:org" checked disabled/>
            <Checkbox label="read:user" checked disabled/>
            <Checkbox label="user:email" checked disabled/>
            <Checkbox label="read:discussion" checked disabled/>
            <Checkbox label="read:enterprise" checked disabled/>
          </div>
        </li>
        <li>Create the token, copy the token's value and paste it below</li>
      </ul>
      <TextInputField
        label="GitHub token"
        description="This is encrypted in transit and at rest and encrypted again with a random encryption key that is unique to your user and not accessible by anybody"
        onChange={(e: ChangeEvent<HTMLInputElement>) => setApiKeyInput(e.target.value)}
        value={apiKeyInput}
      />
    </div>
  </Dialog>

};

const EventsLineChart = ({data}: {data: IHomeAnalyticsResponse}) => {
  const [selectedOrg, setSelectedOrg] = useState('');
  const [historicalIngestProgress, setHistoricalIngestProgress] = useState<Record<number, number>>({});
  const [ghConnectDialogOpen, setGhConnectDialogOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<number | null>(null);
  const [selectedEventType, setSelectedEventType] = useState<string | null>(null);
  const [mergeDialogOpen, setMergeDialogOpen] = useState(false);
  const orgNames = uniq(data.results.map(d => d.org_name));
  const hasNoGitHubConnectors = useAppSelector(getHasNoGitHubConnectors);
  const currentUser = useAppSelector(getCurrentUser);
  const orgOptions = orgNames.map(name => ({label: name, value: name}));
  const contactOptions: {value: number, label: string}[] = data.contacts.map(ctc => ({value: ctc.id, label: getContactName(ctc)}))
  const eventTypeOptions = uniq(data.results.map(res => res.event_type)).map(et => ({label: et, value: et}));

  useEffect(() => {
    if (!hasNoGitHubConnectors && currentUser) {
      listen<{progress: number, job_id: number}>(`user=${currentUser.id}`, 'job.github_historical_ingest.progress', ({progress, job_id}) => {
        setHistoricalIngestProgress({...historicalIngestProgress, [job_id]: Math.round(progress * 100)});
      });
    }
  }, [hasNoGitHubConnectors, currentUser, historicalIngestProgress]);


  useEffect(() => {
    if (!selectedOrg && orgOptions[0]) {
      setSelectedOrg(orgOptions[0].value)
    }
  }, [selectedOrg, orgOptions]);

  const areas: any = [];

  const chartData = makeChartData(data, selectedOrg, selectedContact, selectedEventType);

  if (chartData.length) {
    Object.keys(chartData[0]).forEach(label => {
      if (label !== 'day') {
        areas.push(<Area type="monotone" key={label} dataKey={label} name={label} stroke={stringToColor(label).dark} fillOpacity={1} fill={stringToColor(label).light} />)
      }
    })
  }

  if (hasNoGitHubConnectors) {
    return <div>
      <GitHubConnectDialog isShown={ghConnectDialogOpen} onClose={() => setGhConnectDialogOpen(false)} />
      <EmptyState
        title={'Connect a github account'}
        description={'You need to connect a GitHub account to get started with manager analytics.'}
        icon={<WarningSignIcon color='var(--color-yellow-5)' />}
        iconBgColor='var(--color-yellow-2)'
        primaryCta={
          <Button
            onClick={() => setGhConnectDialogOpen(true)}
          >
            Connect your GitHub Account
          </Button>
        }
      />
    </div>
  }

  return (
    <div className="home--content-section">
      <MergeContactsDialog contacts={data.contacts} onClose={() => setMergeDialogOpen(false)} isShown={mergeDialogOpen} />
      <h4 className="home-content-chart--header">{_(M.HOME_CHART_HEADER)}</h4>
      {!isEmpty(historicalIngestProgress) && <div className="home-gh-job-progress-container">
        <div className="home-gh-job-progress-label">Pulling 90 days of GitHub activity...</div>
        <ProgressBar completed={Math.min(...Object.values(historicalIngestProgress)) || 0} bgcolor='darkblue' />
      </div>}
      <div className="home-content-filter--container">
        <Label>{_(M.ORG)}</Label>
        <Select
          options={orgOptions}
          value={{label: selectedOrg, value: selectedOrg}}
          onChange={(option) => option ? setSelectedOrg(option.value) : undefined}
          isClearable={false}
        />
      </div>
      <div className="home-content-filter--container">
        <Label>{_(M.CONTACT)} <small className="home-content-merge-contacts-prompt" onClick={() => setMergeDialogOpen(true)}>{_(M.HOME_MERGE_CONTACTS_PROMPT)}</small></Label>
        <Select
          options={contactOptions}
          value={contactOptions.find(co => selectedContact === co.value)}
          onChange={(option) => setSelectedContact(option?.value || null)}
          isClearable
        />
      </div>
      {selectedContact && <div className="home-content-filter--container">
        <Label>{_(M.ACTION)}</Label>
        <Select
          options={eventTypeOptions}
          value={eventTypeOptions.find(co => selectedEventType === co.value)}
          onChange={(option) => setSelectedEventType(option?.value || null)}
          isClearable
        />
      </div>}
      <AreaChart width={730} height={250} data={chartData}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorHs" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ff5c35" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#ff5c35" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="day" />
        <YAxis />
        <Tooltip />
        {areas}
      </AreaChart>
    </div>
  );
};


const MgrHomeView = () => {
  const user = useAppSelector(getCurrentUser);
  const text = user && user.full_name ? `, ${user.full_name.split(' ')[0]}` : ` ${_(M.TO)} TinyCadence`;

  const {
    data,
    isLoading,
    isError,
  } = useHomeAnalyticsQuery(undefined);

  return <div className="app--page-default-container">
    <h2 className="home--header">{_(M.HOME_HEADER)}{text}!</h2>
    <div>
      {isLoading && <div>{_(M.LOADING)}<Spinner /></div>}
    </div>
    <div>
      {isError && <div>{_(M.ERROR)}</div>}
    </div>
    {data && <div>
      <EventsLineChart data={data}/>
    </div>}
  </div>
};

export default MgrHomeView;