import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Table, Pagination, Button, EditIcon, Tablist, Tab, IconButton, toaster, BlockedPersonIcon } from 'evergreen-ui';

import { usePrefetch } from 'api/contact';
import _, { M } from 'constants/i18n';
import { EnrollmentsListView } from 'store/cadence/slice';
import { dateStrToLocaleStr, getContactName } from 'utils/strings';
import { useCancelCadenceContactMutation, usePaginateCadenceEnrollmentsQuery, usePrefetch as cadencePrefetch } from 'api/cadence';
import { ICadence, ICadenceEnrollment } from 'types/cadence';
import { IContact } from 'types/contact';
import { TrackEventNames, tracker } from 'utils/tracking';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getHasUnreadThreads } from 'store/user/selector';
import { dismissUnreadThreads } from 'store/user/slice';
import ContactDrawer from 'components/crm/ContactDrawer';

import './style.css';


const EnrollmentRow = ({cadence, cadence_contact: enrollment, contact, editable, onSelectContact}: {cadence: ICadence, contact: IContact, cadence_contact: ICadenceEnrollment, editable: boolean, onSelectContact: (contactId: number) => void}) => {
  const nav = useNavigate();
  const prefetchContact = usePrefetch('fetchContact');
  const [cancel, cancelRes] = useCancelCadenceContactMutation();

  useEffect(() => {
    if (cancelRes.isError) {
      toaster.danger(_(M.ERROR));
    } else if (cancelRes.isSuccess) {
      tracker.track(TrackEventNames.CE, {enrollmentId: enrollment.id})
      toaster.success(_(M.SUCCESS));
    }
  }, [cancelRes.isError, cancelRes.isSuccess, enrollment.id]);

  const unenroll = (e: MouseEvent) => {
    e.stopPropagation();
    cancel({id: enrollment.id});
  }

  const goToEnrollment = (e: MouseEvent) => {
    e.stopPropagation();
    nav(`/cadences/${cadence.id}/enrollments/${enrollment.id}`);
  };

  const goToEdit = (e: MouseEvent) => {
    e.stopPropagation();
    nav(`/cadences/${cadence.id}/enrollments/${enrollment.id}/edit`);
  };

  const selectContact = (e: MouseEvent) => {
    e.stopPropagation();
    onSelectContact(contact.id);
  }

  return (
    <>
      <Table.Row onClick={goToEnrollment} isSelectable>
        <Table.TextCell textProps={{color: 'blue'}} onMouseEnter={() => prefetchContact(contact.id)} onClick={selectContact}>{getContactName(contact)}</Table.TextCell>
        <Table.TextCell>
          {dateStrToLocaleStr(enrollment.started_at || '')}
        </Table.TextCell>
        <Table.TextCell>
          {dateStrToLocaleStr(enrollment.last_step_at || '')}
        </Table.TextCell>
        {editable && (
          <Table.TextCell>
            <IconButton
              icon={EditIcon}
              appearance='minimal'
              onClick={goToEdit}
            />
            <IconButton
              icon={BlockedPersonIcon}
              appearance='minimal'
              onClick={unenroll}
            />
          </Table.TextCell>
        )}
      </Table.Row>
    </>
  );
};

const ViewToStatuses: Record<
  EnrollmentsListView,
  'in_progress' | 'done' | 'paused' | 'scheduled' | 'cancelled'
> = {
  [EnrollmentsListView.DONE]: 'done',
  [EnrollmentsListView.IN_PROGRESS]: 'in_progress',
  [EnrollmentsListView.PAUSED]: 'paused',
  [EnrollmentsListView.SCHEDULED]: 'scheduled',
  [EnrollmentsListView.CANCELLED]: 'cancelled',
};

const EnrollmentsList = () => {
  const [params, setParams] = useSearchParams();
  const offset = isNaN(parseInt(params.get('offset') || '0')) ? 0 : parseInt(params.get('offset') || '0');
  const view = (params.get('view') as EnrollmentsListView) || EnrollmentsListView.IN_PROGRESS;
  const [showContactId, setShowContactId] = useState<null | number>(null);
  const limit = 10;
  const {cadenceId: cadenceIdStr} = useParams<{cadenceId?: string}>();
  const hasUnreadThreads = useAppSelector(getHasUnreadThreads);
  const dispatch = useAppDispatch();
  const fetchEnrollmentList = cadencePrefetch('paginateCadenceEnrollments')
  const cadenceId = cadenceIdStr ? parseInt(cadenceIdStr) : undefined;
  const nav = useNavigate();

  const {
    data,
  } = usePaginateCadenceEnrollmentsQuery({
    limit,
    offset,
    cadenceId,
    status: ViewToStatuses[view] || ViewToStatuses[EnrollmentsListView.IN_PROGRESS],
  });

  // track when data changes (i.e. when enrollments list is requested)
  useEffect(() => {
    if (data) {
      tracker.track(TrackEventNames.LE);
    }
  }, [data]);

  // "dismiss" the red dot when the user looks at the paused enrollments
  useEffect(() => {
    if (view === EnrollmentsListView.PAUSED && hasUnreadThreads && !cadenceId) {
      dispatch(dismissUnreadThreads())
    }
  }, [hasUnreadThreads, view, dispatch, cadenceId]);

  const onChangeView = (newView: EnrollmentsListView) => {
    if (newView === view || !data) return;

    setParams(params => {
      params.set('offset', '0');
      params.set('view', newView);
      return params;
    });
  };

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.meta.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setParams(params => {
        params.set('offset', (currentOffset - limit).toString());
        return params;
      });
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.meta.pagination;

    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setParams(params => {
        params.set('offset', (currentOffset + limit).toString());
        return params;
      });
    }
  };

  const onPageChange = (page: number) => {
    setParams(params => {
      params.set('offset', ((page - 1) * limit).toString());
      return params;
    });
  };

  const rowsAreEditable = [
    EnrollmentsListView.IN_PROGRESS,
    EnrollmentsListView.SCHEDULED,
    EnrollmentsListView.PAUSED
  ].includes(view)

  // TODO: i18n
  return (
    <div>
      <ContactDrawer isShown={!!showContactId} contactId={showContactId} onClose={() => setShowContactId(null)}/>
      <div className="enrollment-list-header--container">
        <Tablist marginBottom={16}>
          <Tab
            aria-controls='panel-in-progress'
            isSelected={view === EnrollmentsListView.IN_PROGRESS}
            key={EnrollmentsListView.IN_PROGRESS}
            onSelect={onChangeView.bind(this, EnrollmentsListView.IN_PROGRESS)}
            onMouseEnter={() => fetchEnrollmentList({limit, offset: 0, cadenceId, status: ViewToStatuses[EnrollmentsListView.IN_PROGRESS]})}
          >
            In Progress
          </Tab>
          <Tab
            aria-controls='panel-scheduled'
            isSelected={view === EnrollmentsListView.SCHEDULED}
            key={EnrollmentsListView.SCHEDULED}
            onSelect={onChangeView.bind(this, EnrollmentsListView.SCHEDULED)}
            onMouseEnter={() => fetchEnrollmentList({limit, offset: 0, cadenceId, status: ViewToStatuses[EnrollmentsListView.SCHEDULED]})}
          >
            Scheduled
          </Tab>
          <Tab
            aria-controls='panel-paused'
            isSelected={view === EnrollmentsListView.PAUSED}
            key={EnrollmentsListView.PAUSED}
            onSelect={onChangeView.bind(this, EnrollmentsListView.PAUSED)}
            onMouseEnter={() => fetchEnrollmentList({limit, offset: 0, cadenceId, status: ViewToStatuses[EnrollmentsListView.PAUSED]})}
          >
            Paused
          </Tab>
          <Tab
            aria-controls='panel-done'
            isSelected={view === EnrollmentsListView.DONE}
            key={EnrollmentsListView.DONE}
            onSelect={onChangeView.bind(this, EnrollmentsListView.DONE)}
            onMouseEnter={() => fetchEnrollmentList({limit, offset: 0, cadenceId, status: ViewToStatuses[EnrollmentsListView.DONE]})}
          >
            Done
          </Tab>
          <Tab
            aria-controls='panel-scheduled'
            isSelected={view === EnrollmentsListView.CANCELLED}
            key={EnrollmentsListView.CANCELLED}
            onSelect={onChangeView.bind(this, EnrollmentsListView.CANCELLED)}
            onMouseEnter={() => fetchEnrollmentList({limit, offset: 0, cadenceId, status: ViewToStatuses[EnrollmentsListView.CANCELLED]})}
          >
            Cancelled
          </Tab>
        </Tablist>
        {cadenceId && <Button
          appearance="primary"
          intent="success"
          onClick={() => nav(`/cadences/${cadenceId}/enroll`)}
        >
          {_(M.ENROLL)}
        </Button>}
      </div>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Contact</Table.TextHeaderCell>
          <Table.TextHeaderCell>Enrolled at</Table.TextHeaderCell>
          <Table.TextHeaderCell>Last step at</Table.TextHeaderCell>
          {rowsAreEditable && (
            <Table.TextHeaderCell></Table.TextHeaderCell>
          )}
        </Table.Head>
        <Table.Body>
          {data?.cadence_contacts.map((cc) => (
            <EnrollmentRow
              key={cc.cadence_contact.id}
              {...cc}
              editable={rowsAreEditable}
              onSelectContact={(contactId) => setShowContactId(contactId)}
            />
          ))}
        </Table.Body>
      </Table>
      {data && (
        <Pagination
          totalPages={data.meta.pagination.last}
          page={data.meta.pagination.this}
          onNextPage={onNextPage}
          onPreviousPage={onPrevPageClick}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
};

export default EnrollmentsList;
