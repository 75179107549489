import { MouseEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faStopwatch, faTag } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { Pagination, Spinner } from 'evergreen-ui';
import cx from 'classnames';

import { usePaginateCadencesQuery, usePrefetch } from 'api/cadence';
import _, {M} from 'constants/i18n';
import { ICadence, CadenceNodeActionType } from 'types/cadence'

import './style.css';


const CadenceItem = ({ cadence, selected }: {cadence: ICadence, selected?: boolean}) => {
  const nav = useNavigate();
  const fetchCadence = usePrefetch('fetchCadence');
  const fetchEnrollments = usePrefetch('paginateCadenceEnrollments');

  const stopPropagation = (e: MouseEvent<HTMLAnchorElement>) => e.stopPropagation()

  const stepCounts: Record<CadenceNodeActionType, number> = {
    'APPLY_LABEL': cadence.data.nodes.filter(n => n.action.type === 'APPLY_LABEL').length,
    'BRANCH': cadence.data.nodes.filter(n => n.action.type === 'BRANCH').length,
    'SEND_EMAIL': cadence.data.nodes.filter(n => n.action.type === 'SEND_EMAIL').length,
    'WAIT': cadence.data.nodes.filter(n => n.action.type === 'WAIT').length,
  }

  // TODO: i18n
  return <div className={cx('cadence-sidebar-list-item--container', {selected})} onClick={() => nav(`/cadences/${cadence.id}/`)} onMouseEnter={() => fetchCadence(cadence.id)}>
    <div className="cadence-sidebar-list-item--title">{cadence.name}</div>
    <div className="cadence-sidebar-list-item--subtitle">
      <span>{_(M.STEPS)}:</span>
      {stepCounts.APPLY_LABEL} <FontAwesomeIcon icon={faTag} className="cadence-sidebar-list-item--icon" />
      {/* {stepCounts.BRANCH} <FontAwesomeIcon icon={faCodeBranch} className="cadence-sidebar-list-item--icon"/> */}
      {stepCounts.SEND_EMAIL} <FontAwesomeIcon icon={faEnvelope} className="cadence-sidebar-list-item--icon"/>
      {stepCounts.WAIT} <FontAwesomeIcon icon={faStopwatch} className="cadence-sidebar-list-item--icon"/>
    </div>
    {selected &&
      <div>
        <NavLink className="cadence-sidebar-list-item--link" end to={`/cadences/${cadence.id}/`}  onClick={stopPropagation}>Cadence Settings</NavLink>
        <NavLink className="cadence-sidebar-list-item--link" to={`/cadences/${cadence.id}/performance`}  onClick={stopPropagation}>Cadence Performance</NavLink>
        <NavLink className="cadence-sidebar-list-item--link" to={`/cadences/${cadence.id}/edit`} onClick={stopPropagation}>Modify Cadence</NavLink>
        <NavLink className="cadence-sidebar-list-item--link" to={`/cadences/${cadence.id}/enrollments`} onClick={stopPropagation} onMouseEnter={() => fetchEnrollments({limit: 10, offset: 0, cadenceId: cadence.id, status: 'done'})}>Manage Threads</NavLink>
        <NavLink className="cadence-sidebar-list-item--link" to={`/cadences/${cadence.id}/enroll`} onClick={stopPropagation}>{_(M.ENROLL)}</NavLink>
      </div>
    }
  </div>
};


const CadencesSidebar = () => {
  const {cadenceId: cadenceIdStr} = useParams<{cadenceId?: string}>();
  const cadenceId = parseInt(cadenceIdStr || '0');
  const limit = 5;
  const [offset, setOffset] = useState(0);
  const {
    isLoading,
    isFetching,
    isError,
    data,
  } = usePaginateCadencesQuery({limit, offset, deleted: false, status: ['active', 'disabled']});

  const loading = isLoading || isFetching;

  if (loading) {
    return <Spinner delay={100}>{_(M.LOADING)}</Spinner>
  }

  if (!loading && !isError && data?.cadences.length === 0) {
    return null;
  }

  const onPrevPageClick = () => {
    if (!data) return;

    const currentOffset = (data.meta.pagination.this - 1) * limit;
    if (currentOffset > 0) {
      setOffset(currentOffset - limit);
    }
  };

  const onNextPage = () => {
    if (!data) return;

    const pagination = data.meta.pagination;
    const currentOffset = (pagination.this - 1) * limit;
    if (pagination.this < pagination.last) {
      setOffset(currentOffset + limit);
    }
  };

  const onPageChange = (page: number) => {
    setOffset((page - 1) * limit);
  };

  return <div className="cadence-sidebar-list--container">
    {data && data.cadences.map((c) => <CadenceItem selected={c.id === cadenceId} cadence={c} key={c.id} />)}
    {data && <Pagination totalPages={data.meta.pagination.last} page={data.meta.pagination.this} onNextPage={onNextPage} onPreviousPage={onPrevPageClick} onPageChange={onPageChange} />}
  </div>;

};

export default CadencesSidebar;