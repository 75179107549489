import { NavLink, Outlet } from 'react-router-dom';

import './style.css';

const AdminOutlet = () => {

  return (
    <div className='admin-outlet--container'>

      <div className='admin-outlet--sidebar'>
        <div className="admin-outlet-links--container">
          <NavLink to="/admin" end className='admin-outlet--link'>Home</NavLink>
          <NavLink to="/admin/users" className='admin-outlet--link'>Users</NavLink>
          <NavLink to="/admin/tasks" className='admin-outlet--link'>Tasks</NavLink>
          <NavLink to="/admin/cadences" className='admin-outlet--link'>Cadences</NavLink>
          <NavLink to="/admin/jobs" className='admin-outlet--link'>Jobs</NavLink>
          <NavLink to="/admin/connectors" className='admin-outlet--link'>Connectors</NavLink>
        </div>
      </div>

      <div className="admin-sidebar-sibling-content--container">
        <Outlet />
      </div>
    </div>

  );
};

export default AdminOutlet;