import ColdSalesEmail from 'components/icons/cadence_templates/cold_sales_email_illustration';
import Empty from 'components/icons/cadence_templates/empty';
import FollowUpFromIntro from 'components/icons/cadence_templates/follow_up_from_intro';
import LabelEmails from 'components/icons/cadence_templates/label_emails';
import ProductWelcomeEmail from 'components/icons/cadence_templates/product_welcome_email';
import UpsellEmailCadence from 'components/icons/cadence_templates/upsell_email_cadence';
import _, { M } from 'constants/i18n';
import { ICreateCadenceArgs } from 'types/cadence';

const meta = {'cadence_schema_version': 1};

const EmptyCadence: ICreateCadenceArgs = {
  name: _(M.NEW_CADENCE_NAME),
  description: _(M.NEW_CADENCE_DESC),
  meta,
  data: {
    options: {
      only_send_email_during_biz_hours: false,
      biz_hour_schedule: null,
      send_emails_as_replies: false,
      pause_before_sending: false,
      only_send_email_on_biz_days: false,
      unenroll_on_mtg_scheduled: false,
      unenroll_on_reply: false
    },
    start_node: null,
    nodes: []
  },
};


const IntroFollowUp: ICreateCadenceArgs = {
  name: _(M.NEW_CADENCE_NAME_FOLLOW_UP),
  description: _(M.NEW_CADENCE_DESC_FOLLOW_UP),
  meta,
  data: {
    options: {
      only_send_email_during_biz_hours: false,
      biz_hour_schedule: null,
      send_emails_as_replies: true,
      pause_before_sending: false,
      only_send_email_on_biz_days: false,
      unenroll_on_mtg_scheduled: true,
      unenroll_on_reply: true,
    },
    start_node: 'cd_ifu_1',
    nodes: [
      {
        id: 'cd_ifu_1',
        next_node_id: 'cd_ifu_2',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'Following up',
            reply_to_thread: true,
            pause_before_sending: false,
            send_during_business_days: false,
            body: {
              text: "Thanks for the intro! Bcc'ing you.\nNice to meet you! I'm glad that we got connected. If you're available, I would love to chat about X.\n\nLet me know when you have some availability in the next week for a quick chat.\nThanks,\nYour name",
              html: "Thanks for the intro! Bcc'ing you.\nNice to meet you! I'm glad that we got connected. If you're available, I would love to chat about X.\n\nLet me know when you have some availability in the next week for a quick chat.\nThanks,\nYour name",
            }
          }
        }
      },
      {
        id: 'cd_ifu_2',
        next_node_id: 'cd_ifu_4',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              weeks: 0,
              days: 3,
              hours: 0,
              minutes: 0,
              seconds: 0,
            }
          }
        }
      },
      {
        id: 'cd_ifu_4',
        next_node_id: null,
        action: {
          type: 'SEND_EMAIL',
          params: {
            // TODO: i18n
            subject: 'Following up',
            reply_to_thread: true,
            pause_before_sending: true,
            send_during_business_days: false,
            body: {
              text: "Hey there, just wanted to follow up on this. If you don't have time right now, that's fine too!\n\nThanks,\nYour name",
              html: "Hey there, just wanted to follow up on this. If you don't have time right now, that's fine too!\n\nThanks,\nYour name",
            }
          }
        }
      }
    ]
  },
};

const ColdEmailCadence: ICreateCadenceArgs = {
  name: 'Cold sales outreach',
  description: 'A sales email sequence template based on a playbook used by Gong to grow to $200M in annual revenue.',
  data: {
    nodes: [
      {
        id: 'cd_cec_1',
        action: {
          type: 'SEND_EMAIL',
          params: {
            body: {
              html: "<p dir=\"ltr\" style=\"text-align: start;\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'there' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'there' }}</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>Looks like you [insert personalized, relevant observation]</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>With your track record of unconventional growth, you know first-hand how difficult it is to [achieve X-goal without sacrificing Y]</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>It’s a lose-lose: You either [accomplish X-goal with Y negative-effect], or [accomplish Y with x negative-effect]</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>We’ve helped [X customers] [achieve positive business outcome].</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>I think we can do the same for you. Up for a chat?</span></p>",
              text: "Hi {{ contact.firstname or 'there' }}Looks like you [insert personalized, relevant observation]With your track record of unconventional growth, you know first-hand how difficult it is to [achieve X-goal without sacrificing Y]It’s a lose-lose: You either [accomplish X-goal with Y negative-effect], or [accomplish Y with x negative-effect]We’ve helped [X customers] [achieve positive business outcome].I think we can do the same for you. Up for a chat?"
            },
            subject: '{{ contact.firstname }} I can help you solve X',
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: 'cd_cec_2'
      },
      {
        id: 'cd_cec_2',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              days: 5
            }
          }
        },
        next_node_id: 'cd_cec_3'
      },
      {
        id: 'cd_cec_3',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'Oops, forgot something',
            body: {
              text: "Hi {{ contact.firstname or 'again' }}I sent you an email last week about X problem and that I think we can help you with it.I forgot to send along a couple links to [proof points].Feel free to read them, I’m happy to answer any questions.If you are facing X problem, can I set up some time with you to hear more about it?",
              html: "<p dir=\"ltr\" style=\"text-align: start;\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'again' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'again' }}</span></p><p dir=\"ltr\"><span>I sent you an email last week about X problem and that I think we can help you with it.</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>I forgot to send along a couple links to [proof points].</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>Feel free to read them, I’m happy to answer any questions.</span></p><p dir=\"ltr\" style=\"text-align: start;\"><span>If you are facing X problem, can I set up some time with you to hear more about it?</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: 'cd_cec_4'
      },
      {
        id: 'cd_cec_4',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              days: 3
            }
          }
        },
        next_node_id: 'cd_cec_5'
      },
      {
        id: 'cd_cec_5',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'Not the right time?',
            body: {
              text: "Hi {{ contact.firstname or 'again' }},I've tried to reach you a couple times now.I'm going to assume that now isn't the right time for you to solve X problem, so I'll leave your inbox some peace.Feel free to reach out if that changes!",
              html: "<p dir=\"ltr\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'again' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'again' }}</span><span>,</span></p><p><br></p><p dir=\"ltr\"><span>I've tried to reach you a couple times now.</span></p><p dir=\"ltr\"><span>I'm going to assume that now isn't the right time for you to solve X problem, so I'll leave your inbox some peace.</span></p><p dir=\"ltr\"><span>Feel free to reach out if that changes!</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: null
      }
    ],
    options: {
      only_send_email_during_biz_hours: true,
      biz_hour_schedule: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        monday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        tuesday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        wednesday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        thursday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        friday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        saturday: null,
        sunday: null,
      },
      unenroll_on_reply: true,
      send_emails_as_replies: false,
      unenroll_on_mtg_scheduled: true,
      only_send_email_on_biz_days: true
    },
    start_node: 'cd_cec_1'
  },
  meta,
}

const PostProductSignup: ICreateCadenceArgs = {
  name: 'Post product signup',
  description: 'A series of emails to have a new user engage more with the product.',
  data: {
    nodes: [
      {
        id: 'cd_pps_1',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              days: 1
            }
          }
        },
        next_node_id: 'cd_pps_2'
      },
      {
        id: 'cd_pps_2',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'Thanks for signing up!',
            body: {
              text: "Hi {{ contact.firstname or 'there' }},Welcome to X!We're so glad you signed up!To get you started, here are a couple links to get the most out of X:link 1link 2link 3Please feel free to reach out (by replying to this email, if you like) for any help. I'd be delighted to help you!",
              html: "<p dir=\"ltr\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'there' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'there' }}</span><span>,</span></p><p dir=\"ltr\"><span>Welcome to X!</span></p><p dir=\"ltr\"><span>We're so glad you signed up!</span></p><p dir=\"ltr\"><span>To get you started, here are a couple links to get the most out of X:</span></p><p dir=\"ltr\"><a href=\"https://google.com\" rel=\"noreferrer\"><span>link 1</span></a></p><p dir=\"ltr\"><a href=\"https://google.com\" rel=\"noreferrer\"><span>link 2</span></a></p><p dir=\"ltr\"><a href=\"https://google.com\" rel=\"noreferrer\"><span>link 3</span></a></p><p dir=\"ltr\"><span>Please feel free to reach out (by replying to this email, if you like) for any help. I'd be delighted to help you!</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: 'cd_pps_3'
      },
      {
        id: 'cd_pps_3',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              weeks: 1
            }
          }
        },
        next_node_id: 'cd_pps_4'
      },
      {
        id: 'cd_pps_4',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: "{{ contact.firstname }} don't forget to X",
            body: {
              text: "Hey {{ contact.firstname or 'again' }}Love seeing all your activity in X! I hope you're getting great value from it.I noticed that you didn't X. If you do, you'll end up getting Y benefit automatically.Maybe this isn't relevant to you, in that case, sorry for the interruption.Again, I'm here if you need any help or assistance!",
              html: "<p dir=\"ltr\"><span>Hey </span><span data-tv-label=\"{{ contact.firstname or 'again' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'again' }}</span></p><p dir=\"ltr\"><span>Love seeing all your activity in X! I hope you're getting great value from it.</span></p><p dir=\"ltr\"><span>I noticed that you didn't X. If you do, you'll end up getting Y benefit automatically.</span></p><p dir=\"ltr\"><span>Maybe this isn't relevant to you, in that case, sorry for the interruption.</span></p><p dir=\"ltr\"><span>Again, I'm here if you need any help or assistance!</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: 'cd_pps_5'
      },
      {
        id: 'cd_pps_5',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              weeks: 2
            }
          }
        },
        next_node_id: 'cd_pps_6'
      },
      {
        id: 'cd_pps_6',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: '{{ contact.firstname }} I noticed that we can improve X for you',
            body: {
              text: "Hey {{ contact.firstname or 'again' }},I just noticed that you were less active in X (which is the goal!), but it's not doing as much for you as it could.Would you be interested in hopping on a quick call to slightly change X and get you some better metrics?If you're busy, we could also just chat over email!Just let me know",
              html: "<p dir=\"ltr\"><span>Hey </span><span data-tv-label=\"{{ contact.firstname or 'again' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'again' }}</span><span>,</span></p><p dir=\"ltr\"><span>I just noticed that you were less active in X (which is the goal!), but it's not doing as much for you as it could.</span></p><p dir=\"ltr\"><span>Would you be interested in hopping on a quick call to slightly change X and get you some better metrics?</span></p><p dir=\"ltr\"><span>If you're busy, we could also just chat over email!</span></p><p dir=\"ltr\"><span>Just let me know</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: null
      }
    ],
    start_node: 'cd_pps_1',
    options: {
      pause_before_sending: true
    }
  },
  meta,
};

const ProuctUpsellEmails: ICreateCadenceArgs = {
  name: 'Product upsell',
  description: 'An end-of-year email sequence designed to get existing customers to upgrade their plan.',
  data: {
    nodes: [
      {
        id: 'cd_pue_1',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'Your annual summary of X',
            body: {
              text: "Hi {{ contact.firstname or 'there' }},It's been a great year with you on X! Here's a quick look on some summary metrics from your account:X metricY metricZ metricIt looks like you've got some great value out of X- thank you for being a customer and here's to another year!In case you haven't checked it out yet, I think X could be a great addition to your account, here's a doc on it and how it works: LINK",
              html: "<p dir=\"ltr\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'there' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'there' }}</span><span>,</span></p><p dir=\"ltr\"><span>It's been a great year with you on X! Here's a quick look on some summary metrics from your account:</span></p><p dir=\"ltr\"><span>X metric</span></p><p dir=\"ltr\"><span>Y metric</span></p><p dir=\"ltr\"><span>Z metric</span></p><p dir=\"ltr\"><span>It looks like you've got some great value out of X- thank you for being a customer and here's to another year!</span></p><p dir=\"ltr\"><span>In case you haven't checked it out yet, I think X could be a great addition to your account, here's a doc on it and how it works: LINK</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: 'cd_pue_2'
      },
      {
        id: 'cd_pue_2',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              days: 2
            }
          }
        },
        next_node_id: 'cd_pue_3'
      },
      {
        id: 'cd_pue_3',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'RE: Your annual summary of X',
            body: {
              text: "Hi {{ contact.firstname or 'again' }},Just wanted to follow up on my last email, wasn't sure if it missed your inbox.I wanted to highlight X feature that I think you'd get great value out of.It's the next tier up and would be an additional $Y per month, but would likely save you $Z per month.Up for a chat?",
              html: "<p dir=\"ltr\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'again' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'again' }}</span><span>,</span></p><p dir=\"ltr\"><span>Just wanted to follow up on my last email, wasn't sure if it missed your inbox.</span></p><p dir=\"ltr\"><span>I wanted to highlight X feature that I think you'd get great value out of.</span></p><p dir=\"ltr\"><span>It's the next tier up and would be an additional $Y per month, but would likely save you $Z per month.</span></p><p dir=\"ltr\"><span>Up for a chat?</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: 'cd_pue_4'
      },
      {
        id: 'cd_pue_4',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              weeks: 0,
              days: 10
            }
          }
        },
        next_node_id: 'cd_pue_5'
      },
      {
        id: 'cd_pue_5',
        action: {
          type: 'SEND_EMAIL',
          params: {
            subject: 'For you: 25% off the pro plan for X',
            body: {
              text: "Hi {{ contact.firstname or 'again' }},I'll leave your inbox in peace after this, but I do think you'd get great value out of X feature. Especially since you seem to Y.I feel confident enough that I'd like to offer you 2 free months of the X plan upgrade.I could be totally missing the mark here- it wouldn't be the first time!Happy to answer any questions you might have about it.Can I schedule some time with you?",
              html: "<p dir=\"ltr\"><span>Hi </span><span data-tv-label=\"{{ contact.firstname or 'again' }}\" data-tv-value=\"firstname\" data-tv-type=\"contactProperty\">{{ contact.firstname or 'again' }}</span><span>,</span></p><p dir=\"ltr\"><span>I'll leave your inbox in peace after this, but I do think you'd get great value out of X feature. Especially since you seem to Y.</span></p><p dir=\"ltr\"><span>I feel confident enough that I'd like to offer you 2 free months of the X plan upgrade.</span></p><p dir=\"ltr\"><span>I could be totally missing the mark here- it wouldn't be the first time!</span></p><p dir=\"ltr\"><span>Happy to answer any questions you might have about it.</span></p><p dir=\"ltr\"><span>Can I schedule some time with you?</span></p>"
            },
            reply_to_thread: false,
            pause_before_sending: true,
            send_during_business_days: false
          }
        },
        next_node_id: null
      }
    ],
    start_node: 'cd_pue_1',
    options: {
      only_send_email_during_biz_hours: true,
      biz_hour_schedule: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        monday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        tuesday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        wednesday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        thursday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        friday: {start_hour: 9, start_minute: 0, end_hour: 17, end_minute: 0},
        saturday: null,
        sunday: null,
      },
      unenroll_on_reply: true,
      send_emails_as_replies: true,
      unenroll_on_mtg_scheduled: true,
      only_send_email_on_biz_days: false,
    },
  },
  meta,
}

export const LabelReplies: ICreateCadenceArgs = {
  name: 'Label emails with no reply',
  description: 'A simple cadence that labels Gmail threads if there is no reply or upcoming meeting.',
  data: {
    nodes: [
      {
        id: 'cd_lr_1',
        action: {
          type: 'APPLY_LABEL',
          params: {
            labels: [],
            remove_other_labels: true
          }
        },
        next_node_id: 'cd_lr_2'
      },
      {
        id: 'cd_lr_2',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              days: 3
            }
          }
        },
        next_node_id: 'cd_lr_3'
      },
      {
        id: 'cd_lr_3',
        action: {
          type: 'APPLY_LABEL',
          params: {
            labels: [],
            remove_other_labels: true
          }
        },
        next_node_id: 'cd_lr_4'
      },
      {
        id: 'cd_lr_4',
        action: {
          type: 'WAIT',
          params: {
            td_kwargs: {
              weeks: 1
            }
          }
        },
        next_node_id: 'cd_lr_5'
      },
      {
        id: 'cd_lr_5',
        action: {
          type: 'APPLY_LABEL',
          params: {
            labels: [],
            remove_other_labels: true
          }
        },
        next_node_id: null
      }
    ],
    start_node: 'cd_lr_1',
    options: {
      unenroll_on_reply: true,
      unenroll_on_mtg_scheduled: true
    }
  },
  meta,
};


export const CadenceTemplates = [
  {
    Icon: Empty,
    cadence: EmptyCadence,
  },
  {
    cadence: LabelReplies,
    Icon: LabelEmails,
  },
  {
    Icon: FollowUpFromIntro,
    cadence: IntroFollowUp,
  },
  {
    Icon: ColdSalesEmail,
    cadence: ColdEmailCadence,
  },
  {
    cadence: PostProductSignup,
    Icon: ProductWelcomeEmail,
  },
  {
    cadence: ProuctUpsellEmails,
    Icon: UpsellEmailCadence,
  }
];