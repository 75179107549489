import {$createParagraphNode, $getRoot, $insertNodes, COMMAND_PRIORITY_NORMAL, EditorState, EditorThemeClasses, KEY_ENTER_COMMAND, LexicalEditor } from 'lexical';
import debounce from 'lodash.debounce';
import {useEffect, useState } from 'react';
import {InitialConfigType, LexicalComposer} from '@lexical/react/LexicalComposer';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import cx from 'classnames';

import { TemplateVariableNode, TemplateVariablePlugin } from './templateVar';
import ToolbarPlugin from './toolbar';
import './style.css';
import './composer.css';

const theme: EditorThemeClasses = {
  // Theme styling goes here
  text: {
    bold: 'tc-composer-text--bold',
    italic: 'tc-composer-text--italic',
    strikethrough: 'tc-composer-text--strikethrough',
    underline: 'tc-composer-text--underline',
  },
  templateVariable: 'tc-composer--template-var',
  paragraph: 'tc-compose--paragraph',
}

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

interface IOnEnterParams {
  html: string
  plainText: string
  clearEditor: () => void
}

function InitializePlugin({initialHtmlString, onEnter}: {initialHtmlString?: string, onEnter?: (params: IOnEnterParams) => void}) {
  const [editor] = useLexicalComposerContext();
  const [didInitialize, setDidInitialize] = useState(false);

  useEffect(() => {
    const unregister = editor.registerCommand(KEY_ENTER_COMMAND, () => {
      if (onEnter) {
        const clearEditor = () => {
          editor.update(() => {
            $getRoot().clear();
          });
        };
        const html = $generateHtmlFromNodes(editor, null)
        const plainText = editor.getRootElement()?.textContent || '';
        onEnter({ html, plainText, clearEditor });
      }
      return true;
    }, COMMAND_PRIORITY_NORMAL);
    return () => {
      unregister();
    }
  }, [onEnter, editor]);

  useEffect(() => {
    if (initialHtmlString && !didInitialize) {
      setDidInitialize(true);
      editor.update(() => {
        const parser = new DOMParser();
        const dom = parser.parseFromString(initialHtmlString, 'text/html');
        const nodes = $generateNodesFromDOM(editor, dom);
        $getRoot().select();
        // empty the root elt
        $getRoot().select().getNodes().map(n => n.remove());
        $insertNodes(nodes);
      })
    }
  }, [initialHtmlString, didInitialize, editor]);

  return null;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
const onError = (error: Error) => {
  console.error(error);
}

const ComposerPlaceholder = ({small, placeholder}: {small: boolean, placeholder: string}) => {
  const [editor] = useLexicalComposerContext();
  return <div className={cx('composer-content-editable--placeholder', {small})} onClick={() => editor.focus()}>{placeholder}</div>
}

const Composer = ({
  initialHtmlString,
  onChange,
  onChangeDebounceMs=100,
  placeholder='Enter some text...',
  onEnter,
  disableToolbar,
}: {
  initialHtmlString?: string,
  onChange?: (value: {html: string, plainText: string}) => void,
  onChangeDebounceMs?: number,
  placeholder?: string,
  onEnter?: (params: IOnEnterParams) => void,
  disableToolbar?: boolean
}) => {
  const initialConfig: InitialConfigType = {
    namespace: 'TCEditor',
    theme,
    onError,
    nodes: [
      HeadingNode,
      LinkNode,
      ListNode,
      QuoteNode,
      ListItemNode,
      TemplateVariableNode,
    ],
    html: {
      import: {
        'div': (node) => {
          return {
            conversion: (elt) => {
              const newNode = $createParagraphNode();
              // const text = $createTextNode(elt.innerText);
              // newNode.append(text);
              return {node: newNode};
            }
          }
        }
      }
    }
  };

  const internalOnChange = debounce((newState: EditorState, editor: LexicalEditor) => {
    if (onChange) {
      newState.read(() => {
        const html = $generateHtmlFromNodes(editor, null)
        const plainText = editor.getRootElement()?.textContent || '';
        onChange({html, plainText});
      })
    }
  }, onChangeDebounceMs);

  return (
    <div className="composer-editor--wrapper">
      <LexicalComposer initialConfig={initialConfig}>
        <InitializePlugin initialHtmlString={initialHtmlString} onEnter={onEnter} />
        {disableToolbar ? <></> : <ToolbarPlugin />}
        <RichTextPlugin
          contentEditable={<ContentEditable className={cx('composer-content-editable', {small: disableToolbar})} />}
          placeholder={<ComposerPlaceholder placeholder={placeholder} small={disableToolbar || false} />}
          // placeholder={<div className={cx('composer-content-editable--placeholder', {small: disableToolbar})}>{placeholder}</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <ListPlugin />
        <LinkPlugin />
        <TemplateVariablePlugin />
        <HistoryPlugin />
        <MyCustomAutoFocusPlugin />
        <OnChangePlugin onChange={internalOnChange}/>
      </LexicalComposer>
    </div>
  );
}

export default Composer;