import { Dialog, Label, WarningSignIcon, toaster } from 'evergreen-ui';
import Select from 'react-select';

import _, {M} from 'constants/i18n';
import { useMergeContactsMutation } from 'api/contact';
import { useState } from 'react';
import { IContactWithProperties } from 'types/contact';
import { getContactName } from 'utils/strings';


const MergeContactsDialog = ({
  isShown,
  contacts,
  onClose,
}: {isShown: boolean, contacts: IContactWithProperties[], onClose: () => void}) => {
  const [merge, result] = useMergeContactsMutation();
  const [primaryContact, setPrimaryContact] = useState<number>();
  const [secondaryContact, setSecondaryContact] = useState<number>();

  const options = contacts.map(contact => ({label: getContactName(contact), value: contact.id}));

  const onConfirm = () => {
    if (!primaryContact || !secondaryContact) {
      // TODO: notify user of error
      return;
    }
    merge([primaryContact, secondaryContact])
      .then(res => {
        if ('data' in res && res.data.status === 'ok') {
          toaster.success(_(M.SUCCESS));
          setPrimaryContact(undefined);
          setSecondaryContact(undefined);
          onClose();
        } else {
          toaster.danger(_(M.ERROR));
        }
      })
  }

  const onCloseComplete = () => {
    setPrimaryContact(undefined);
    setSecondaryContact(undefined);
    onClose();
  }

  return <Dialog
    isShown={isShown}
    title={_(M.MERGE_CONTACTS_DIALOG_TITLE)}
    confirmLabel={_(M.CONFIRM)}
    cancelLabel={_(M.CANCEL)}
    onConfirm={onConfirm}
    onCloseComplete={onCloseComplete}
    isConfirmLoading={result.isLoading}
    isConfirmDisabled={!primaryContact || !secondaryContact || primaryContact === secondaryContact}
  >
    <div className="merge-contacts-dialog--section">{_(M.MERGE_CONTACTS_DIALOG_DESC)}</div>
    <div className="merge-contacts-dialog--section">
      <Label>{_(M.MERGE_CONTACTS_DIALOG_PRIMARY_LABEL)}</Label>
      <Select
        options={options.filter(op => op.value !== secondaryContact)}
        onChange={op => setPrimaryContact(op?.value)}
        value={options.find(op => op.value === primaryContact)}
        isClearable
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </div>
    <div className="merge-contacts-dialog--section">
      <Label>{_(M.MERGE_CONTACTS_DIALOG_SECONDARY_LABEL)}</Label>
      <Select
        options={options.filter(op => op.value !== primaryContact)}
        onChange={op => setSecondaryContact(op?.value)}
        value={options.find(op => op.value === secondaryContact)}
        isClearable
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        menuPortalTarget={document.body}
      />
    </div>
    <div className="merge-contacts-dialog--section warning"><WarningSignIcon className="merge-contacts-dialog--icon"/>{_(M.MERGE_CONTACTS_DIALOG_WARNING)}</div>
  </Dialog>
};

export default MergeContactsDialog;